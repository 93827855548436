import React, { useState, useContext, useEffect } from 'react'
import UseCookie from '../../utils/cookies/useCookie';
import {LoginInfoContext} from '../../context/loginInfo'
import { gql, useApolloClient, useMutation } from "@apollo/client";
import { signIn,signUp } from "../../apollo/mutations";
import { ThemeContext } from '../../context/Theme';
import Colors from '../../utils/colors/Colors';
import { LocationContext } from '../../context/Location';
import { CartContext } from '../../context/Cart';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import {QueryAllCustomerTypes} from '../../apollo/queries'
import Loader from '../../ui-components/Loader/Loader';

const SIGNIN = gql`
  ${signIn}
`;

const SIGNUP = gql`
  ${signUp}
`;

const getAllCustomerTypes = gql`
  ${QueryAllCustomerTypes}
`;


export default function SignIn(props) {
    const client = useApolloClient();
    const [mobileNumber, setMobileNumber] = useState('');
    var [enterOtp,setEnterOtp] = useState(false)
    var [loading,setLoading] = useState(false)
    const { setCustomCookie } = UseCookie();
    // const { setCartData } = useContext(CartContext)
    const { setLocation } = useContext(LocationContext)
    const { systemThemeMode,appColorTheme, setAppColorTheme} = useContext(ThemeContext)
    const colors = Colors[appColorTheme=='systemDefault'?systemThemeMode:appColorTheme];
    // var { loginInfo,setLoginInfo } = useContext(LoginInfoContext)
    const [SignIn] = useMutation(SIGNIN, { onCompleted, onError });
    const [SignUp] = useMutation(SIGNUP, { SIGNUPCompleted, SIGNUPError });
    const navigateScreenTo = useNavigate();

    async function onCompleted({ login }) {
        //console.log("🚀 ~ onCompleted ~ login:", login)
      }
    async function SIGNUPCompleted({ login }) {
        //console.log("🚀 ~ onCompleted ~ login:", login)
      }
    
      function onError(errors) {
        //console.log("🚀 ~ onError ~ errors:", errors)
      }

      function SIGNUPError(errors) {
        //console.log("🚀 ~ onError ~ errors:", errors)
      }

      useEffect(() => {
        if(props){
            console.log("🚀 ~ useEffect ~ props:", props)
            document?.getElementById(`phone-input`)?.focus();
        }
      },[props])

    const handleChange = (event) => {
        const { value } = event.target;
        setMobileNumber(value.trim());
      };
    
    const [inputs, setInputs] = useState(["","","",""]);

    const handleKeyDown = (e, index) => {
        if (
        !/^[0-9]{1}$/.test(e.key)
        && e.key !== 'Backspace'
        && e.key !== 'Delete'
        && e.key !== 'Tab'
        && !e.metaKey
        ) {
        e.preventDefault();
        }

        if (e.key === 'Delete' || e.key === 'Backspace') {
        if (index > 0) {
            setInputs(prevInputs => {
            const newInputs = [...prevInputs];
            newInputs[index] = '';
            return newInputs;
            });
        }
        }
    };

    const handleInput = (e, index) => {
        const { value } = e.target;
        setInputs(prevInputs => {
        const newInputs = [...prevInputs];
        newInputs[index] = value;
        return newInputs;
        });
        if (value && index < inputs.length - 1) {
        document?.getElementById(`otp-input-${index + 1}`)?.focus();
        }
    };

    const handlePaste = (e) => {
        e.preventDefault();
        const text = e.clipboardData.getData('text');
        if (!new RegExp(`^[0-9]{${inputs.length}}$`).test(text)) {
        return;
        }
        const digits = text.split('');
        setInputs(digits);
        document?.getElementById('otp-submit')?.focus();
    };

    const fetchAllCustomerTypes = async(loginInfo) => {
        try{
          const { data } = await client.query({
            query: getAllCustomerTypes,
            variables:{},
            fetchPolicy: 'no-cache',
            context: {
              headers: {
                Authorization: `Bearer ${loginInfo?.jwtToken}`,
              },
            },
          });
          await setCustomCookie('allCustomerTypes',JSON.stringify(data?.getAllCustomerTypes))
          let generalCustomerType = await fetchGeneralCustomerTypeIfPremium(data?.getAllCustomerTypes,loginInfo)
          await setCustomCookie('generalCustomerType',JSON.stringify(generalCustomerType))
        }
        catch(error){
          toast?.error("Error to fetch all customer types")
        }
      }

          //This works only for a premuim customer and return general customer type
    const fetchGeneralCustomerTypeIfPremium = async(allCustomerTypes,loginInfo)=>{
        let index = allCustomerTypes?.findIndex(data=>data?.default == true) //default true indicates that customer is a general customer , not premium customer
        if(loginInfo?.customerType != allCustomerTypes[index]?.customerType){
          return allCustomerTypes[index]?.customerType
        }
        //this is to display general customer price, along with premium price
      }

    const verifyAccount = async()=>{
        let requestBody = {
          "phoneNumber": '+91'+mobileNumber,
          "otp": inputs.join(''),
          "longitude": "77.59104670126453",
          "latitude": "13.04269368886535",
        }
        setInputs(["","","",""])
        loading = true
        setLoading(loading)
            //console.log("🚀 ~ verifyAccount ~ requestBody:", requestBody)
        try{
            let response = await SignIn({ variables:requestBody})
            if(response?.data?.customerVerifyOTP?.jwtToken){
                let addresses = response?.data?.customerVerifyOTP?.addresses
                let loginInfo = response?.data?.customerVerifyOTP
                let locationDetails = {}
                if(addresses && addresses?.length>0){
                    let index = addresses?.findIndex((value)=>value?.selected==true)
                    locationDetails = {
                        label:addresses[index]?.label,
                        deliveryAddress: `${addresses[index]?.details},${addresses[index]?.deliveryAddress}`,
                        latitude: addresses[index]?.location?.coordinates[1],
                        longitude: addresses[index]?.location?.coordinates[0]
                    }
                    // setLocation(locationDetails)
                    loginInfo.defaultAddress = locationDetails
                }
                delete loginInfo.addresses
                delete loginInfo.cart
                // setLoginInfo(loginInfo)
                await setCustomCookie('loginInfo',JSON.stringify(loginInfo))
                setLocation(locationDetails)
                //console.log("🚀 ~ verifyAccount ~ loginInfo:", loginInfo)
                props?.eventToVerifyAccount(loginInfo)
                await fetchAllCustomerTypes(loginInfo)
                toast.success('Login Successful')
            }
            else{
                toast.error('Invalid otp!')
            }
        }catch(e){
          toast.error('Invalid otp!')
        }
        loading = false
        setLoading(loading)
    }

    const sendOtp = async()=>{
        let requestBody = {
            "phoneNumber": "+91"+mobileNumber
        }
        loading = true
        setLoading(loading)
        try{
            let data = await SignUp({ variables:requestBody})
            //console.log("🚀 ~ sendOtp ~ data:", data)
            enterOtp=true;
            setEnterOtp(enterOtp);
        }catch(error){
            toast.error("Error : ",error)
            //console.log("🚀 ~ sendOtp ~ error:", error)
        }
        loading = false
        setLoading(loading)
    }

    function generateRandomName() {
        // Array of possible first names
        const firstNames = ['John', 'Emma', 'Michael', 'Sophia', 'James', 'Olivia', 'William', 'Ava', 'Alexander', 'Mia'];
      
        // Array of possible last names
        const lastNames = ['Smith', 'Johnson', 'Williams', 'Jones', 'Brown', 'Davis', 'Miller', 'Wilson', 'Moore', 'Taylor'];
      
        // Randomly select a first name and last name
        const randomFirstName = firstNames[Math.floor(Math.random() * firstNames.length)];
        const randomLastName = lastNames[Math.floor(Math.random() * lastNames.length)];
      
        // Return the generated name
        return `${randomFirstName} ${randomLastName}`;
      }

      const handleKeyPress = (e, index) => {
        if (e.nativeEvent.key === 'Backspace' && index > 0 && !inputs[index]) {
          document?.getElementById(`otp-input-${index - 1}`)?.focus();
        }
      };

  return (
    <>
        <div className="text-center p-4">
            {!enterOtp ? 
            <div className="max-w-md mx-auto text-center px-4 sm:px-8 py-5">
                <header className="mb-5 flex flex-col items-center">
                    <div className="flex-shrink-0 items-center">
                        <img
                            className="h-14 sm:h-14 md:h-14 lg:h-20 xl:h-20 xxl:h-20 w-auto"
                            src={
                                process.env.REACT_APP_PLACEHOLDER_IMAGE
                              }
                            alt="NativeNest"
                        />
                    </div>
                    <h3 className={`text-md sm:text-md md:text-xl lg:text-2xl xxl:text-2xl font-bold text-${colors?.heading}`}>Log in or Sign up</h3>
                </header>

                <div className="flex items-center">
                    <div id="dropdown-phone-button" data-dropdown-toggle="dropdown-phone" className="bg-appThemeColorVeryLight flex-shrink-0 z-10 inline-flex items-center py-2.5 px-4 text-sm font-medium text-center text-gray-900 border border-appThemeColor rounded-s-lg focus:ring-4 cursor-default outline-none focus:outline-none ring-appThemeColorDark dark:bg-appThemeColorDark dark:text-white dark:border-appThemeColorDark border-appThemeColor" type="button">
                        +91
                    </div>
                    <label htmlFor="phone-input" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Phone number:</label>
                    <div className="relative w-full">
                        <input type="text" onChange={handleChange} id="phone-input" aria-describedby="helper-text-explanation" style={{backgroundColor:Colors.colorTheme[colors?.navColor],color:Colors.colorTheme[colors?.heading]}} className={`text-${colors?.heading} block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 border border-appThemeColorDark outline-none focus:outline-none appearance-none rounded-e-lg border-s-0 border border-appThemeColor focus:ring-0 focus:border-appThemeColorDark dark:bg-gray-50 dark:border-s-appThemeColorDark border-appThemeColorDark dark:border-appThemeColorDark dark:placeholder-gray-400 dark:text-white dark:focus:border-appThemeColorDark placeholder:tracking-normal tracking-widest placeholder:font-normal font-bold`} pattern="[0-9]{10}" placeholder="Enter your mobile number" required />
                    </div>
                </div>
                <p id="helper-text-explanation" className="my-2 text-sm text-gray-500 dark:text-gray-400">We will send you an SMS with a verification code.</p>
                <button type="button" disabled={mobileNumber.length != 10}  onClick={sendOtp} className={`text-white w-full focus:ring-0  bg-appThemeColorDark hover:bg-appThemeColorVeryDark focus:ring-4 focus:ring-appThemeColorDark font-medium rounded-lg px-5 py-2.5 me-2 mb-2 dark:bg-appThemeColorDark dark:hover:bg-appThemeColorVeryDark focus:outline-none focus:outline-none dark:focus:ring-appThemeColorDark disabled:opacity-50 disabled:cursor-not-allowed ${(mobileNumber.length != 10)?'opacity-50 cursor-not-allowed' : 'opacity-100 cursor-pointer transition duration-100 transform active:scale-95'}`}><text className='text-sm text-white'>Send verification code</text></button>
            </div>
            :
            <div className="max-w-md mx-auto text-center px-4 sm:px-8 py-5 ">
                <header className="mb-8">
                    <h1 className={`text-md sm:text-md md:text-xl lg:text-2xl xxl:text-2xl font-bold mb-1 text-${colors?.heading}`}>Mobile Phone Verification</h1>
                    <p className="text-sm sm:text-sm md:text-md lg:text-lg xl:text-xl xxl:text-xl text-slate-500">Enter the 4-digit verification code that was sent to your phone number.</p>
                </header>
                <div id="otp-form">
                    <div className="flex items-center justify-center gap-3">
                    {inputs.map((value, index) => (
                        <input
                        key={index}
                        id={`otp-input-${index}`}
                        onKeyUpCapture={(e) => handleKeyPress(e, index)}
                        type="text"
                        className={`text-${colors?.premiumText} w-8 sm:w-10 md:w-10 lg:w-12 xl:w-14 h-8 sm:h-10 md:h-10 lg:h-12 xl:h-14  text-center text-sm sm:text-sm md:text-md lg:text-lg xl:text-xl xxl:text-xl font-extrabold text-slate-900 bg-appThemeColorVeryLight border border-transparent hover:border-appThemeColor appearance-none rounded  outline-none focus:outline-none focus:bg-white focus:border-appThemeColorDark-400 ring-0 focus:ring-2 focus:ring-appThemeColorDark focus:border-none `}
                        maxLength="1"
                        // value={value}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        onInput={(e) => handleInput(e, index)}
                        onFocus={(e) => e.target.select()}
                        onPaste={handlePaste}
                        />
                    ))}
                    </div>
                    <div className="max-w-[260px] mx-auto mt-4">
                    <button
                        id="otp-submit"
                        type="button"
                        disabled={inputs.join("").length != 4}
                        onClick={verifyAccount}
                        className={`w-full inline-flex justify-center whitespace-nowrap rounded-lg bg-appThemeColorDark px-3.5 py-2.5 text-sm font-medium text-white shadow-sm shadow-appThemeColorDark-950/10 hover:bg-appThemeColorDark-600 outline-none focus:outline-none focus:ring-0 focus:ring-appThemeColorDark-300 transition-colors duration-150 disabled:opacity-50 disabled:cursor-not-allowed ${(inputs.join("").length != 4)?'opacity-50 cursor-not-allowed' : 'opacity-100 cursor-pointer transition duration-100 transform active:scale-95'}`}
                        >
                        Verify Account
                        </button>

                    </div>
                </div>
                <div className="text-sm text-slate-500 mt-4">Didn't receive code? <button onClick={sendOtp} className="font-medium text-appThemeColorDark hover:text-appThemeColorDark-600 cursor-pointer transition duration-100 transform active:scale-90">Resend</button></div>
            </div>}
            <div id="helper-text-explanation" className="flex flex-row items-center align-center justify-center mt-4 text-xs text-gray-500 dark:text-gray-400 ">
                <text>{"By continuing, you agree to our "} </text> &nbsp;
                <div style={{ cursor: "pointer" }} onClick={() => navigateScreenTo("/TermsAndConditions")} className='underline underline-offset-1'>
                    <text>Terms of service</text>
                </div> 
                <div>
                    &nbsp;<text>&</text>&nbsp;
                </div>
                <div style={{ cursor: "pointer" }} onClick={() => navigateScreenTo("/PrivacyPolicy")} className='underline underline-offset-2'>
                    Privacy policy
                </div>
            </div>
        </div>
        <Loader loading={loading} />
    </>
  )
}
