import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import GroceryItemCard from "../../ui-components/Cards/GroceryItemCard";
import CategoryItemCard from "../../ui-components/Cards/CategoryItemCard";
import CardLoader from "../../ui-components/Cards/CardLoader";
import { useLocation, useNavigate } from "react-router-dom";
import {
  QueryCustomerLandingPage,
  QueryAvailableProducts,
} from "../../apollo/queries";
import { gql, useApolloClient, useLazyQuery, useQuery } from "@apollo/client";
import { CartContext } from "../../context/Cart";
import LandingPageLoading from "../../ui-components/LandingPageLoading/LandingPageLoading";
import UseCookie from "../../utils/cookies/useCookie";
import { ThemeContext } from "../../context/Theme";
import Colors from "../../utils/colors/Colors";
import { LocationContext } from "../../context/Location";
import { FaAngleLeft } from "react-icons/fa6";
import { FaChevronRight } from "react-icons/fa";
import ImageCarousel from "../../ui-components/ImageCarousel/ImageCarousel";
import PremuimBanner from "../../ui-components/PremuimBanner/PremuimBanner";
import SignIn from "../SignIn/SignIn";

const getLandingPageData = gql`
  ${QueryCustomerLandingPage}
`;

const getAllProducts = gql`
  ${QueryAvailableProducts}
`;

export default function HomePage(props) {
  // console.log("Welcome to Landing page")
  const { cartItems, addToCart, removeFromCart, setCartData } =
    useContext(CartContext);
  const { location } = useContext(LocationContext);
  const { systemThemeMode, appColorTheme, setAppColorTheme } =
    useContext(ThemeContext);
  const colors =
    Colors[appColorTheme == "systemDefault" ? systemThemeMode : appColorTheme];
  var [refreshing, setRefreshing] = useState(false);
  var [isLoading, setIsLoading] = useState(false);
  var [loginInfo, setLoginInfo] = useState("");
  var [pageNumber, setPageNumber] = useState(0);
  var [allProducts, setAllProducts] = useState([]);
  const [loadingMore, setLoadingMore] = useState(false);
  var [landingPageData, setlandingPageData] = useState({});
  const { getCustomCookie, removeCustomCookie, setCustomCookie } = UseCookie();
  const groceryListItemRefs = useRef([]);
  const leftButtonRefs = useRef([]);
  const rightButtonRefs = useRef([]);
  var [premuimBanner, setPremuimBanner] = useState(false);
  var [sortedDisplayItems, setSortedDisplayItems] = useState([]);
  const client = useApolloClient();
  const { pathname } = useLocation();
  var [generalCustomerType, setGeneralCustomerType] = useState(false);

  // // Initialize refs dynamically
  // useEffect(() => {
  //   groceryListItemRefs.current = groceryListItemRefs.current.slice(0, listings.length);
  // }, [listings]);

  const scrollLeft = (index) => {
    if (groceryListItemRefs.current[index]) {
      let data = groceryListItemRefs.current[index];
      // console.log("🚀 ~ scrollLeft ~ groceryListItemRefs.current[index]:", data?.scrollHeight, data?.scrollWidth,  data?.clientWidth)
      groceryListItemRefs.current[index].scrollBy({
        left: -500,
        behavior: "smooth",
      });
      // if(groceryListItemRefs.current[index].scrollWidth > groceryListItemRefs.current[index].clientWidth){
      //   leftButtonRefs.current[index].style.display = 'none';
      // }
    }
  };

  const scrollRight = (index) => {
    if (groceryListItemRefs.current[index]) {
      groceryListItemRefs.current[index].scrollBy({
        left: 500,
        behavior: "smooth",
      });
      // leftButtonRefs.current.style.display = 'none';
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [loadingMore]);

  useEffect(() => {
    //checkOrderStatus()
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    intitialLoad();
  }, []);

  const checkOrderStatus = async () => {
    let orderDetails = await getCustomCookie("checkOrderDetailsStatus");
    console.log("🚀 ~ checkOrderStatus ~ orderDetails:", orderDetails);
    if (orderDetails?.orderId) {
      navigateScreenTo(
        `/Order/OrderDetails/${orderDetails?.orderId?.toString()}`
      );
      // window.location.href  = `${process.env.REACT_APP_SERVER_URL}/Order/OrderDetails/${orderDetails?.orderId?.toString()}`
    }
  };

  useEffect(() => {
    console.log("Welcome");
    intitialLoad();
  }, [location]);

  const intitialLoad = async () => {
    //await checkOrderStatus();
    pageNumber = 0;
    setPageNumber(pageNumber);
    verifyLoginInfo();
    reloadLandingPageData();
  };

  const openPremuimBanner = useCallback(async (value) => {
    premuimBanner = value;
    setPremuimBanner(premuimBanner);
  }, []);

  const loadMoreItems = async () => {
    // let locationInfo = await getCustomCookie('locationInfo')
    // console.log("🚀 ~ loadMoreItems ~ locationInfo:", locationInfo,location)

    let requestBody = {
      longitude:
        "" + (location?.longitude ? location?.longitude : "77.59104670126453"),
      latitude:
        "" + (location?.latitude ? location?.latitude : "13.04269368886535"),
      limit: 42,
      skip: 0,
    };
    const {
      data,
      loading,
      error: allProductsError,
    } = await client.query({
      query: getAllProducts,
      variables: requestBody,
      fetchPolicy: "network-only",
    });

    if (data) {
      if (data?.queryAvailableProducts?.displayItems[0]?.items) {
        allProducts = data.queryAvailableProducts.displayItems[0].items;
        // allProducts = allProducts.concat(data.queryAvailableProducts.displayItems[0].items);
        setAllProducts(allProducts);
        // pageNumber++;
        // setPageNumber(pageNumber);
      }
      refreshing = false;
      setRefreshing(refreshing);
      isLoading = false;
      setIsLoading(isLoading);
      setLoadingMore(false);
    }
  };

  const handleScroll = () => {
    if (
      window.innerHeight + window.scrollY >=
        document.documentElement.scrollHeight - window.innerHeight * 0.5 &&
      !loadingMore
    ) {
      //console.log(window.innerHeight, window.scrollY , document.documentElement.scrollHeight , window.innerHeight * 0.2)
      // loadMoreItems();
    }
  };

  const reloadLandingPageData = async () => {
    let loginInfo = await getCustomCookie("loginInfo");
    console.log("🚀 ~ reloadLandingPageData ~ location:", location);
    try {
      console.log("🚀 ~ reloadLandingPageData ~ location:", location);
      isLoading = true;
      setIsLoading(isLoading);
      refreshing = true;
      setRefreshing(refreshing);
      // let locationInfo = await getCustomCookie('locationInfo')
      // console.log("🚀 ~ reloadLandingPageData ~ locationInfo:", locationInfo,location)

      let requestBody = {
        longitude:
          "" +
          (location?.longitude ? location?.longitude : "77.59104670126453"),
        latitude:
          "" + (location?.latitude ? location?.latitude : "13.04269368886535"),
        device: 1,
      };
      const { data: landingPageDetails } = await client.query({
        query: getLandingPageData,
        variables: requestBody,
        fetchPolicy: "network-only",
        context: {
          headers: {
            Authorization: `Bearer ${loginInfo?.jwtToken}`,
          },
        },
      });
      landingPageData = landingPageDetails;
      setlandingPageData(landingPageData);
      await verifyLoginInfo();
      if (landingPageData) {
        // Sort the displayItems array by sortOrder
        let sortedDisplayItems = [];
        if (landingPageData?.queryCustomerLandingPage?.displayItems) {
          sortedDisplayItems = [
            ...landingPageData?.queryCustomerLandingPage?.displayItems,
          ]?.sort((a, b) => a?.sortOrder - b?.sortOrder);
          // sortedDisplayItems = sortedDisplayItems.map(data=>{return {...data,rows:2,columns:3}})
          setSortedDisplayItems(sortedDisplayItems);
        }
        if (landingPageData?.queryCustomerLandingPage?.customer?.phone) {
          let allCustomerTypes = await getCustomCookie("allCustomerTypes");
          if (allCustomerTypes) {
            let generalCustomerType = await fetchGeneralCustomerTypeIfPremium(
              allCustomerTypes,
              landingPageData?.queryCustomerLandingPage?.customer
            );
            setGeneralCustomerType(generalCustomerType);
            await setCustomCookie(
              "generalCustomerType",
              JSON.stringify(generalCustomerType)
            );
          }
        }
        setCartData(
          landingPageData?.queryCustomerLandingPage?.customer?.cart,
          null,
          cartItems
        );
        setCustomCookie(
          "defaultCustomerType",
          JSON.stringify(
            landingPageData?.queryCustomerLandingPage?.customer?.customerType
          )
        );
        setCustomCookie(
          "isLocationServicable",
          JSON.stringify(
            landingPageData?.queryCustomerLandingPage?.isLocationServicable
          )
        );
        setCustomCookie(
          "premiumMembershipMessage",
          JSON.stringify(
            landingPageData?.queryCustomerLandingPage?.premiumMembershipMessage
          )
        );
        setCustomCookie(
          "paymentMethodInfo",
          JSON.stringify(
            landingPageData?.queryCustomerLandingPage?.customer
              ?.permittedPaymentOptions
          )
        );
        setCustomCookie(
          "addressInfo",
          JSON.stringify(
            landingPageData?.queryCustomerLandingPage?.customer?.addresses
          )
        );
        await loadMoreItems();
      }

      refreshing = false;
      setRefreshing(refreshing);
      isLoading = false;
      setIsLoading(isLoading);
    } catch (error) {
      console.log("🚀 ~ reloadLandingPageData ~ error:", error);
      refreshing = false;
      setRefreshing(refreshing);
      isLoading = false;
      setIsLoading(isLoading);
      //console.error('Error while refreshing landingPageData:', error);
    }
  };

  //This works only for a premuim customer and return general customer type
  const fetchGeneralCustomerTypeIfPremium = async (
    allCustomerTypes,
    loginInfo
  ) => {
    let index = allCustomerTypes?.findIndex((data) => data?.default == true); //default true indicates that customer is a general customer , not premium customer
    if (loginInfo?.customerType != allCustomerTypes[index]?.customerType) {
      return allCustomerTypes[index]?.customerType;
    }
    //this is to display general customer price, along with premium price
  };

  const navigateScreenTo = useNavigate();
  // var [loading,setLoading] = useState(false)

  const onCardClick = (item) => {
    item?.mrp
      ? navigateScreenTo("/productDetails")
      : navigateScreenTo("/categories");
  };

  const verifyLoginInfo = async () => {
    loginInfo = await getCustomCookie("loginInfo");
    setLoginInfo(loginInfo);
  };

    

  const OfferItemCard = ({ data, ListItemIndex, columns }) => {
  
    const navigateOfferProduct = () => {
      navigateScreenTo(data?.offerImage?.toString()?.trim()?.replace(/\u200B/g, "") ||"/categories");
    };
  
  
    return (
      <div
        key={ListItemIndex}
        className={` flex flex-col items-center justify-center`}
        onClick={navigateOfferProduct}
      >
        <img
          src={ data?.image } // Default image
          alt={ data?.name || 'Item'} //[0 - RECT , 1- CIRCLE]
          className={`object-cover hover:scale-105 relative m-2 w-24 sm:w-24 md:w-35 lg:w-40 xl:w-40 h-22 sm:h-22 md:h-25 lg:h-30 xl:h-30 max-h-xs flex flex-col text-center justify-between overflow-hidden rounded shadow-md hover:shadow-xl transform transition-transform duration-500 ease-in-out cursor-pointer ${data?.sortOrder == 0 ? `bg-${colors?.bottomTabBg} dark:bg-${colors?.bottomTabBg} rounded-md` : 'bg-transparent rounded-full'}`}
        />
  
        {data?.name && (
          <div
            className="text-center text-gray-800 font-medium mt-2 text-sm line-clamp-2"
          >
            {data?.name}
          </div>
        )}
      </div>
    );
  };

  const ItemsListing = ({ item, ListItemIndex }) => {
    console.log("🚀 ~ ItemsListing ~ item?.displayName:", item)
    if(item?.itemType == "SPECIAL_OFFERS"){
      return <>
        <OfferItemCard data={item} columns={item?.columns} ListItemIndex={ListItemIndex}  />
      </>
    }
    else{
    return (
      <>
        <div className="bg-white m-1 rounded-lg cursor-pointer">
          <GroceryItemCard
            openPremuimBanner={openPremuimBanner}
            generalCustomerType={generalCustomerType}
            isLocationServicable={
              landingPageData?.queryCustomerLandingPage?.isLocationServicable
            }
            customerType={
              loginInfo?.customerType != undefined
                ? loginInfo?.customerType
                : landingPageData?.queryCustomerLandingPage?.customer
                    ?.customerType
            }
            cartItems={cartItems}
            addToCart={addToCart}
            removeFromCart={removeFromCart}
            key={ListItemIndex}
            item={item}
          />
        </div>
        {/* <div className="mt-2 flex overflow-x-scroll hide-scroll-bar">
                <div className="flex flex-nowrap ">
                    {false ?(data?.items.map((item,subListItemIndex)=>
                        <div className='bg-white m-2 rounded-lg cursor-pointer'  ><GroceryItemCard clickAction={onCardClick} key={subListItemIndex} item={item} /></div>
                    )):
                    Array.from({ length: Math.ceil((data?.items?.length || 0) / (data?.rows || 1)) }).map((_, categoryIndex) => (
                        <div>
                          {console.log(categoryIndex,Math.ceil((data?.items?.length || 0) / (data?.rows || 1)),"categoryIndex")}
                            {(Array.from({ length: Math.ceil((data?.items?.length || 0) / (data?.rows || 1))-1 == categoryIndex ? (data?.items?.length % data?.rows)==0?data?.rows:data?.items?.length % data?.rows: data.rows }).map((item,subListItemIndex)=><div className='bg-white m-2 rounded-lg cursor-pointer'><GroceryItemCard clickAction={onCardClick} key={subListItemIndex} item={data.items[categoryIndex * data.rows + subListItemIndex]} /></div>))}
                        </div>
                    ))}
                </div>
            </div> */}
      </>
    );
  }
  };


  const Banners = ({ data }) => {
    const images = data?.items?.map((banner, index) => ({
      src: banner?.name,
      alt: `Image ${index + 1}`,
      callBackAction: () => {
        navigateScreenTo(`${banner?.image || "/categories"}`);
      },
    }));

    return (
      <div className="container mx-auto">
        <ImageCarousel
          images={images}
          colors={{ cardsCollectionBg: "gray-200" }}
        />
      </div>
    );
  };

  return (
    <>
      {isLoading ? (
        <LandingPageLoading />
      ) : (
        <div className="">
          {/* <div className={`my-2 shadow-md rounded-lg bg-${colors?.cardsCollectionBg} dark:bg-${colors?.cardsCollectionBg}`}>
              <img src={banner} className="w-full h-full object-cover rounded-lg" alt="Item" />
            </div> */}
          {/* <div className={`shadow-md rounded-lg overflow-hidden bg-${colors?.cardsCollectionBg} dark:bg-${colors?.cardsCollectionBg}`}>
            </div> */}
          {/* <img
            src={
              "https://res.cloudinary.com/nrootsdev/image/upload/v1718014569/Home%20Page%20-%20Banners/HomePageBanner_xf1yko.png"
            }
            className={`w-full h-full sm:h-full md:h-52 lg:h-72 xl:h-72 object-cover shadow-md rounded-lg overflow-hidden bg-${colors?.cardsCollectionBg} dark:bg-${colors?.cardsCollectionBg}`}
            alt="Item"
          /> */}
          {/* <div className="container mx-auto">
            <ImageCarousel images={images} colors={{cardsCollectionBg: 'gray-200'}} />
          </div> */}
          {/* <div className={`my-2 w-full h-96 shadow-md rounded-lg bg-${colors?.cardsCollectionBg} dark:bg-${colors?.cardsCollectionBg} bg-cover bg-center`} style={{ backgroundImage: `url(${banner})` }}>
            </div> */}

          {landingPageData &&
            sortedDisplayItems?.map((data, index) =>
              data?.displayName != "BANNERS" ? (
                <div
                  className={`my-2 p-0.5p shadow-md rounded-lg bg-${colors?.cardsCollectionBg} dark:bg-${colors?.cardsCollectionBg}`}
                  key={index+"sortedDisplayItems"}
                >
                  {data?.displayName && (
                    <div className="flex flex-shrink-0 justify-between items-center px-1p">
                      <span
                        className={`text-md sm:text-md md:text-md lg:text-lg xl:text-lg text-${colors?.heading} font-bold`}
                      >
                        {data.displayName?.split("_")?.join(" ")}
                      </span>
                      {data?.displayName == "CATEGORIES" && (
                        <button
                          className="text-sm text-appThemeColorVeryDark font-bold"
                          onClick={() => {
                            navigateScreenTo("/Categories");
                          }}
                        >
                          See All
                        </button>
                      )}
                    </div>
                  )}
                  <div className="relative flex flex-shrink-0 justify-between items-center ">
                    {data.rows == 1 ? (
                      <>
                        <div
                          ref={(e) => (groceryListItemRefs.current[index] = e)}
                          className="overflow-x-auto whitespace-nowrap scroll-smooth scrollbar-hide flex snap-x justify-between hide-scroll-bar"
                        >
                          {groceryListItemRefs?.current[index]?.scrollWidth >
                            groceryListItemRefs?.current[index]
                              ?.clientWidth && (
                            <div
                              ref={(e) => (leftButtonRefs.current[index] = e)}
                              className=" hidden xs:hidden sm:hidden md:block lg:block xl:block"
                              onClick={() => scrollLeft(index)}
                            >
                              <div
                                style={{
                                  backgroundColor:
                                    Colors.colorTheme[colors?.navColor],
                                  borderColor:
                                    Colors.colorTheme[colors?.buttonBackground],
                                }}
                                className={` cursor-pointer z-10 -left-4 bottom-1/2 rounded-full mx-auto absolute w-6 sm:w-6 md:w-8 lg:w-10 xl:w-10 h-6 sm:h-6 md:h-8 lg:h-10 xl:h-10 shadow-lg shadow-appThemeColorVeryLight border-2 border-${colors?.navColor} transition duration-200 transform hover:scale-110 flex items-center justify-center`}
                              >
                                <FaAngleLeft className="text-sm sm:text-sm md:text-md  lg:text-lg  xl:text-xl text-appThemeColorVeryDark font-bold" />
                              </div>
                            </div>
                          )}
                          {groceryListItemRefs?.current[index]?.scrollWidth >
                            groceryListItemRefs?.current[index]
                              ?.clientWidth && (
                            <div
                              ref={(e) => (rightButtonRefs.current[index] = e)}
                              className=" hidden xs:hidden sm:hidden md:block lg:block xl:block"
                              onClick={() => scrollRight(index)}
                            >
                              <div
                                style={{
                                  backgroundColor:
                                    Colors.colorTheme[colors?.navColor],
                                  borderColor:
                                    Colors.colorTheme[colors?.buttonBackground],
                                }}
                                className={` cursor-pointer z-10 -right-4 bottom-1/2 rounded-full mx-auto absolute w-6 sm:w-6 md:w-8 lg:w-10 xl:w-10 h-6 sm:h-6 md:h-8 lg:h-10 xl:h-10 shadow-lg shadow-appThemeColorVeryLight border-2 border-${colors?.navColor} transition duration-200 transform hover:scale-110 flex items-center justify-center`}
                              >
                                <FaChevronRight className="text-sm sm:text-sm md:text-md  lg:text-lg  xl:text-xl text-appThemeColorVeryDark font-bold" />
                              </div>
                            </div>
                          )}
                          {data.items.map((item, index) => (
                            <div key={index + item?.itemId}>
                              <ItemsListing item={item} ListItemIndex={index} />
                            </div>
                          ))}
                        </div>
                      </>
                    ) : (
                      <div className="flex flex-wrap ">
                        {data.items.map((item, index) => (
                          <div key={index +"item HomePage"} className="">
                            <ItemsListing item={item} ListItemIndex={index} />
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <Banners key={index+"Banner"} data={data} />
              )
            )}
          {landingPageData && (
            <div
              className={`my-2 shadow-md rounded-lg bg-${colors?.cardsCollectionBg} dark:bg-${colors?.cardsCollectionBg}`}
            >
              <div className="flex flex-col justify-center items-center">
                <div className="flex flex-wrap justify-center items-center">
                  {allProducts.map((item, index) => (
                    <ItemsListing
                      key={index}
                      item={item}
                      ListItemIndex={index}
                    />
                  ))}
                </div>
              </div>
            </div>
          )}
          {/* {loading && !data && !loadingMore && (
            <div className="flex justify-center">
              <div
                className={`loader ease-linear rounded-full border-2 border-t-8 border-gray-200 h-10 w-10`}
              ></div>
            </div>
          )}
          {allProductsError && (
            <div className="text-center py-4">
              Error: {allProductsError?.message}
            </div>
          )} */}
          {premuimBanner && (
            <PremuimBanner
              Colors={Colors}
              colors={colors}
              banner={premuimBanner}
              openPremuimBanner={openPremuimBanner}
            />
          )}
        </div>
      )}
      <br />
    </>
  );
}
